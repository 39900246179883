// 运营管理接口
import request from '@/request/request'

// banner列表
export const BannerListApi = (params) => request.get(`/frame-web/admin/banner/list`, {params});
// banner新增
export const BannerAddApi = (params) => request.post(`/frame-web/admin/banner/add`, params);
// banner修改
export const BannerUpdApi = (params) => request.post(`/frame-web/admin/banner/upd`, params);
// banner详情
export const BannerDetailApi = (params) => request.get(`/frame-web/admin/banner/getOne`, {params});
// banner删除
export const BannerDelApi = (params) => request.get(`/frame-web/admin/banner/del`, {params});
// banner排序
export const BannerSortApi = (params) => request.post(`/frame-web/admin/banner/sort`, params);


// 新闻列表
export const NewsListApi = (params) => request.post(`/frame-web/admin/news/page`, params);
// 新闻新增
export const NewsAddApi = (params) => request.post(`/frame-web/admin/news/add`, params);
// 新闻修改
export const NewsUpdApi = (params) => request.post(`/frame-web/admin/news/upd`, params);
// 新闻详情
export const NewsDetailpi = (params) => request.get(`/frame-web/admin/news/getOne`, {params})
// 新闻删除
export const NewsDelpi = (params) => request.get(`/frame-web/admin/news/del`, {params});
// 新闻排序
export const NewsSortpi = (params) => request.post(`/frame-web/admin/news/sort`, params);


// 客户列表
export const PartnerListApi = (params) => request.post(`/frame-web/admin/partner/page`, params);
// 客户新增
export const PartnerAddApi = (params) => request.post(`/frame-web/admin/partner/add`, params);
// 客户修改
export const PartnerUpdApi = (params) => request.post(`/frame-web/admin/partner/upd`, params);
// 客户详情
export const PartnerDetailpi = (params) => request.get(`/frame-web/admin/partner/getOne`, {params})
// 客户删除
export const PartnerDelpi = (params) => request.get(`/frame-web/admin/partner/del`, {params});
// 客户排序
export const PartnerSortpi = (params) => request.post(`/frame-web/admin/partner/sort`, params);

