/**
 * 节流
 * func 要执行的回调函数
 * wait 延时的时间
 */
let timer, flag;
export const throttle = (func, wait = 1000) => {
  if (!flag) {
    flag = true;
    // 如果是非立即执行，则在wait毫秒内的结束处执行
    timer = setTimeout(() => {
      flag = false;
      typeof func === "function" && func();
    }, wait);
  }
};

/**
 * 防抖
 * fn 要执行的回调函数
 * time 延时的时间
 */
export const debounce = (fn, time = 1000) => {
  let timer = null; // 使用闭包缓存结果
  return function() {
    //判断是否存在定时器
    if (timer) {
      //还存在定时器,清除定时器
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply(this, arguments);
    }, time);
  };
};

/**
 * 时间格式化
 * date 时间函数 new Date()
 * fmt 时间格式 yyyy-MM-dd HH:mm:ss
 */
export const format = (date, fmt) => {
  var o = {
    "M+": date.getMonth() + 1, // 月份
    "d+": date.getDate(), // 日
    "H+": date.getHours(), // 时
    "m+": date.getMinutes(), // 分
    "s+": date.getSeconds(), // 秒
    "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};
// 按钮返回code的函数  code -- 按钮的code值
export const codeFn = (code) => {
  let powerListArr = JSON.parse(localStorage.getItem("powerListArr"));
  let newCode = "";
  powerListArr.map((item) => {
    item.children.forEach((i) => {
      if (i.code == code) {
        newCode = code;
      }
      i.children.forEach((el) => {
        if (el.code == code) {
          newCode = code;
        }
      });
    });
  });
  return newCode;
};
// 获取当前时间的格式化
export const getNowFormatDate = () => {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate;
  return currentdate;
};
// 切割时间 返回 - 00:00:00  value -> 秒钟
export const formatSeconds = (value) => {
  let result = parseInt(value);
  let h =
    Math.floor(result / 3600) < 10
      ? "0" + Math.floor(result / 3600)
      : Math.floor(result / 3600);
  let m =
    Math.floor((result / 60) % 60) < 10
      ? "0" + Math.floor((result / 60) % 60)
      : Math.floor((result / 60) % 60);
  let s =
    Math.floor(result % 60) < 10
      ? "0" + Math.floor(result % 60)
      : Math.floor(result % 60);
  return `${h}:${m}:${s}`;
};
